import React from 'react'
import { Grid, Card, CardMedia, CardContent, Typography, Link } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const ProductsNavBar = ({ t, greenNavImg, zipAreaNavImg, adventureNavImg, greenSubHeader, zipSubHeader, adventureSubHeader }) => {
  const elements = Object.freeze({
    greens: 'product-item-greens',
    zipArea: 'product-item-zip-area',
    adventure: 'product-item-adventure'
  })
  const scrollToPosition = (element) => {
    const elementToScrollTo = document.getElementById(element)
    const elementPosition = elementToScrollTo.getBoundingClientRect().top
    const offsetPosition = elementPosition - 65 + window.pageYOffset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }
  const handleClick = (element) => {
    switch (element) {
      case elements.greens:
        scrollToPosition(elements.greens)
        break
      case elements.zipArea:
        scrollToPosition(elements.zipArea)
        break
      case elements.adventure:
        scrollToPosition(elements.adventure)
        break
      default:
        break
    }
  }
  return (
    <Grid container className='nav-bar-container'>
      <Grid item xs={12} md={4}>
        <Card className='product-card' onClick={() => handleClick(elements.greens)}>
          <CardMedia
            className='product-image'
            image={greenNavImg}
            title='Tekonurmipioneeri'
          />
          <CardContent className='product-description'>
            <Typography variant='h5' component='h2'>
              {t('green')}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {greenSubHeader}
            </Typography>
            <Link component='button' className='link-button' onClick={() => handleClick(elements.greens)}>Lue lisää tuotteesta</Link>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card className='product-card' onClick={() => handleClick(elements.zipArea)}>
          <CardMedia
            className='product-image'
            image={zipAreaNavImg}
            title='Product image'
          />
          <CardContent className='product-description zip-area'>
            <Typography variant='h5' component='h2'>
              {t('zipAreasAndGolfMats')}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {zipSubHeader}
            </Typography>
            <Link component='button' className='link-button' onClick={() => handleClick(elements.zipArea)}>Lue lisää tuotteesta</Link>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card className='product-card' onClick={() => handleClick(elements.adventure)}>
          <CardMedia
            className='product-image'
            image={adventureNavImg}
            title='Product image'
          />
          <CardContent className='product-description adventure-golf'>
            <Typography variant='h5' component='h2'>
              {t('adventureGolf')}
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              {adventureSubHeader}
            </Typography>
            <Link component='button' className='link-button' onClick={() => handleClick(elements.adventure)}>Lue lisää tuotteesta</Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default withTranslation()(ProductsNavBar)
