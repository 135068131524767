import ContactForm from '../ContactForm/index'
import ProductTeaser from '../ProductTeaser/ProductTeaser'
import Hero from '../Hero/Hero'
import AboutTeaser from '../About/AboutTeaser'
import { Container, Grid } from '@material-ui/core'
import References from '../ReferenceList/References'
import MediaTeaser from '../Media/MediaTeaser'
import green3 from '../../media/projectImages/greeni-3.jpg'
import green4 from '../../media/projectImages/greeni-4.jpg'
import advGolf from '../../media/projectImages/adventure-golf.jpg'
import aurinko from '../../media/referenceImages/aurinko.png'
import aulanko from '../../media/referenceImages/aulanko.jpg'
import kultaranta from '../../media/referenceImages/kultaranta.jpg'

const referenceImages = [aurinko, aulanko, kultaranta, aurinko, aulanko, kultaranta, aurinko, aulanko, kultaranta, aurinko, aulanko, kultaranta]

const Home = () => {
  return (
    <Container disableGutters maxWidth={false}>
      <Hero />
      <Grid container>
        <AboutTeaser blockSize={6} showMoreButton='true' />
        <MediaTeaser />
      </Grid>
      <ProductTeaser image={green3} heading='Golftekonurmi' description='Toteutamme viheriöasennuksia ympäri Suomen. Vuosien kokemus takaa kestävän harjoittelupaikan hankalimpaankin maastoon. Breikit ja kaadot rakennetaan asennusvaiheessa asiakkaan toiveiden mukaisiksi!' />
      <ProductTeaser image={green4} theme='green' imageOnRight heading='Lyöntimatot ja lähipelialueet' description='InterGREENS-lyöntimattoa käytetään monella virallisella 18 reiän kentällä Suomessa lyöntipaikkanurmena.' />
      <ProductTeaser image={advGolf} theme='sand' heading='Adventure golf' description='Adventure golf on uudenlainen tapa pelata golfia. Tarjoamme adventure golf -kentän suunnittelun sekä toteutuksen.' />
      <References referenceImages={referenceImages} referencesPerRow={3} />
      <ContactForm />
    </Container>
  )
}

export default Home
