import React from 'react'
import { AppBar, Grid, Typography, Button } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'react-router-dom'
import logo from '../../media/intergreens-logo.png'
import { withTranslation } from 'react-i18next'
import i18n from '../../i18n'

class Header extends React.Component {
  componentDidMount () {
    window.addEventListener('scroll', this.resizeHeaderOnScroll)
  }

  resizeHeaderOnScroll () {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop
    const shrinkOn = 150

    const headerEl = document.getElementById('main-header')

    if (distanceY > shrinkOn) {
      headerEl.classList.add('smaller')
    } else {
      headerEl.classList.remove('smaller')
    }
  }

  setLocale (locale) {
    i18n.changeLanguage(locale)
  }

  toggleMobileMenu () {
    const headerLinksEl = document.getElementById('header-links-wrapper')

    if (!headerLinksEl.classList.contains('visible')) {
      this.openMobileMenu()
    } else {
      this.hideMobileMenu()
    }
  }

  openMobileMenu () {
    const headerLinksEl = document.getElementById('header-links-wrapper')
    const headerEl = document.getElementById('main-header')

    headerEl.classList.add('visible')
    headerEl.classList.remove('smaller')
    headerLinksEl.classList.add('visible')
    document.body.classList.add('overflow-hidden')
  }

  hideMobileMenu () {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop
    const shrinkOn = 150
    const headerLinksEl = document.getElementById('header-links-wrapper')
    const headerEl = document.getElementById('main-header')

    headerEl.classList.remove('visible')

    if (distanceY > shrinkOn) {
      headerEl.classList.add('smaller')
    }

    headerLinksEl.classList.remove('visible')
    document.body.classList.remove('overflow-hidden')
  }

  render () {
    const { t } = this.props
    return (
      <AppBar id='main-header' position='fixed'>
        <Link className='logo-link' to='/'>
          <img alt='logo' className='logo' src={logo} />
        </Link>
        <Grid container className='header-container' alignItems='center' justify='flex-end'>
          <Grid container className='header-links-container'>
            <Button className='mobile-menu-toggler hide-lg' onClick={() => this.toggleMobileMenu()}>
              <MenuIcon className='open-menu' />
              <CloseIcon className='close-menu' />
            </Button>
            <Grid id='header-links-wrapper' item className='header-links'>
              <Link to='/' onClick={() => this.hideMobileMenu()}>{t('home')}</Link>
              <Link to='/products' onClick={() => this.hideMobileMenu()}>{t('products')}</Link>
              <Link to='/about' onClick={() => this.hideMobileMenu()}>{t('about')}</Link>
              <Link to='/contact' onClick={() => this.hideMobileMenu()}>{t('contact')}</Link>
            </Grid>
          </Grid>
          <Grid container item className='language-bar' justify='flex-end'>
            {
              this.props.i18n.language === 'fi'
                ? <Typography component='a' className='change-language' onClick={() => this.setLocale('en')}>In English</Typography>
                : <Typography component='a' className='change-language' onClick={() => this.setLocale('fi')}>Suomeksi</Typography>
            }
          </Grid>
        </Grid>
      </AppBar>
    )
  }
}

export default withTranslation()(Header)
