import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Typography, Grid } from '@material-ui/core'
import logo from '../../media/intergreens-logo.png'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { withTranslation } from 'react-i18next'

const Footer = ({ t }) => {
  return (
    <Container component='footer' id='footer' maxWidth={false} disableGutters>
      <Container className='footer-top' maxWidth={false}>
        <Container className='footer-links' maxWidth='md'>
          <Link to='/products'>{t('products')}</Link>
          <Link to='/about'>{t('about')}</Link>
          <Link to='/references'>{t('references')}</Link>
          <Link to='/contact'>{t('contact')}</Link>
        </Container>
        <Container className='contact-details' maxWidth='md'>
          <Typography component='h3'>{t('contact')}</Typography>
          <Grid className='detail' container direction='row' spacing={1}>
            <Grid item>
              <EmailIcon />
            </Grid>
            <Grid item>
              <Typography>kalle@intergreens.com</Typography>
            </Grid>
          </Grid>
          <Grid className='detail' container direction='row' spacing={1}>
            <Grid item>
              <PhoneIcon />
            </Grid>
            <Grid item>
              <Typography>+358 40 733 4720</Typography>
            </Grid>
          </Grid>
          <Grid className='detail' container direction='row' spacing={1}>
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item>
              <Typography>InterGREENS, Suursuontie 6 A 3, 00630 Helsinki</Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container className='footer-bottom' maxWidth={false}>
        <img alt='logo' className='logo' src={logo} />
        <Grid className='copyright-container' container>
          <Typography component='p' className='copyright'>InterGREENS &copy; 2021. {t('allRightsReserved')}.</Typography>
          <Link to='/privacypolicy' className='privacy-policy-link'>{t('privacyPolicy')}</Link>
        </Grid>
      </Container>
    </Container>
  )
}

export default withTranslation()(Footer)
