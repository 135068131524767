import { Grid, Typography } from '@material-ui/core'

const About = ({ header, contents }) => {
  return (
    <Grid container item className='about-content'>
      <Typography component='h2' className='about-header'>{header}</Typography>
      {contents.map((content, idx) =>
        <Typography key={idx} className='about-text' variant='body1'>
          {content}
        </Typography>
      )}
    </Grid>
  )
}

export default About
