export const resources = {
  fi: {
    translation: {
      home: 'Etusivu',
      about: 'Meistä',
      references: 'Referenssit',
      customers: 'Asiakkaitamme',
      contact: 'Ota yhteyttä',
      accept: 'Hyväksy',
      readMore: 'Lue lisää',
      showMore: 'Näytä lisää',
      moreAbout: 'Lisää meistä',
      send: 'Lähetä',
      cookieText: 'Tämä verkkosivusto käyttää evästeitä parantamaan käyttäjäkokemusta.',
      allRightsReserved: 'Kaikki oikeudet pidätetään',
      privacyPolicy: 'Tietosuojaseloste',
      policyText: 'Keräämme tietoa käyttäjistä ja heidän toiminnastaan verkkosivuillamme analysointitarkoituksiin. Hyväksymällä evästeet annat meille suostumuksen tähän.',
      blogPublished: 'Julkaistu',
      products: 'Tuotteet',
      green: 'Golftekonurmi',
      zipAreasAndGolfMats: 'Lähipelialueet ja lyöntimatot',
      adventureGolf: 'Adventure golf',
      productBodyGreen: 'Tarjoamme juuri golfia varten kehitettyä nurmea, joka kestää Lapin arktiset olosuhteet ja Hangon helteet. Laadukkailla InterGREENS-tekonurmilla saat ammattitason viheriön omaan käyttöösi, mökille järven rantaan tai talon takapihalle. InterGREENS golftekonurmi on valmistettu UV-suojatusta polyamedikuiduista, joka kestää erinomaisesti sadetta, lunta, tuulta, jäätä ja auringonpaistetta. InterGREENS-golftekonurmea on asennettu Lapista aina Dubaihin asti. Teimme ensimmäiset viheriöasennuksemme Suomessa vuonna 2005. Kyseiset viheriöt ovat yhä hyvässä pelikunnossa, vaikka viheriöt on asennettu klubien harjoitusalueille, joissa kulutus on paljon suurempaa kuin yksityisille rakennetuilla viheriöillä.',
      productBodyZip: 'Golfviheriömme toimivat hyvin myös klubeilla ja talviharjoitteluhalleissa lähipelialueina ja kohdeviheriöinä. InterGREENS-lyöntimattoa käytetään monella virallisella 18 reiän kentällä Suomessa lyöntipaikkanurmena. Tämä helpottaa lyöntipaikkojen ylläpitoa ja on kestävä ratkaisu luonnonnurmen rinnalle.',
      productBodyAdventure: 'Adventure golf on uudenlainen tapa pelata golfia. Rata tuo haastetta niin nuorille osaajille kuin vanhoille konkareillekin. Ratojen kallistusten ja muotoilun yhdistelmä takaa pelaajalle viihdyttävän ja ainutlaatuisen pelikokemuksen. Korkealaatuinen InterGREENS-tekonurmi vastaa täydellisesti adventure golfin asettamiin materiaalivaatimuksiin. Pitkälle jalostettu materiaali kestää kentän kovan kulutuksen ja on helposti muotoiltavissa.',
      productGreenSub: 'Kaipaako lähipelisi pientä hiomista?',
      productZipSub: 'Klubin lähipelialueen yhteyteen voidaan liittää kestäviä lyöntialustoja chippailua varten',
      productAdventureSub: 'Uudenlainen tapa pelata golfia'
    }
  },
  en: {
    translation: {
      home: 'Home',
      about: 'About',
      references: 'References',
      customers: 'Our customers',
      contact: 'Contact us',
      accept: 'Accept',
      readMore: 'Read more',
      showMore: 'Show more',
      moreAbout: 'More about us',
      send: 'Send',
      cookieText: 'This website uses cookies to enhance the user experience.',
      allRightsReserved: 'All rights reserved',
      privacyPolicy: 'Privacy policy',
      policyText: 'We collect data of our visitors and their actions on our website. By accepting cookies you consent to this.',
      blogPublished: 'Published',
      products: 'Products',
      green: 'Golf green',
      zipAreasAndGolfMats: 'Zip areas and golf mats',
      adventureGolf: 'Adventure golf',
      productBodyGreen: 'Greens in english Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Ciceros De Finibus Bonorum et Malorum for use in a type specimen book.',
      productBodyZip: 'Zip area in englishi Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Ciceros De Finibus Bonorum et Malorum for use in a type specimen book.',
      productBodyAdventure: 'Adventuregolf english Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Ciceros De Finibus Bonorum et Malorum for use in a type specimen book.',
      productGreenSub: 'Best greens for things and stuff',
      productZipSub: 'Superb zipping areas and driving mats for your club',
      productAdventureSub: 'Forget minigolf, adventure golf is here'
    }
  }
}
