import { Typography, Grid, Button, Card, CardContent } from '@material-ui/core'
import RenderGate from '../RenderGate/renderGate'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const AboutTeaser = ({ blockSize, showMoreButton, t }) => {
  return (
    <Grid container item justify='center' className='about-teaser' md={blockSize}>
      <Card className='about-card'>
        <Grid container className='about-teaser-wrapper'>
          <CardContent>
            <Typography component='p' className='text'>
              Lorem ipsum dorem solom mikä on INTERGREENS ja mitä se tekee.
            </Typography>
            <Typography component='p' className='text'>
              InterGREENS-laatu takaa viheriön kestävyyden vuosiksi eteenpäin.
              Erinomaisen golfgriinin lisäksi pihasi on helppohoitoinen ja hyvännäköinen.
            </Typography>
            <RenderGate shouldRender={showMoreButton}>
              <Link to='/about'>
                <Button className='link-button'>{t('moreAbout')}</Button>
              </Link>
            </RenderGate>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  )
}

export default withTranslation()(AboutTeaser)
