import { Grid, Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const ProductTeaser = ({ heading, description, image, imageOnRight, theme, t }) => {
  return (
    <Grid container className={imageOnRight ? 'product-teaser MuiPaper-elevation1 img-right' : 'product-teaser MuiPaper-elevation1'}>
      <Grid item xs={12} md={6}>
        <Grid item xs={12} className='teaser-image'>
          <img alt='green' src={image} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container item className={'teaser-text ' + theme}>
          <Typography component='h2'>{heading}</Typography>
          <Typography component='p' className='description'>{description}</Typography>
          <Link to='/products'>
            <Button className='link-button'>{t('products')}</Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withTranslation()(ProductTeaser)
