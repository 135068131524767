import React from 'react'
import Form from './Form'
import { postReq } from './api/mail'

class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  handleInputChange (event) {
    if (!event) return
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }

  handleButtonClick () {
    postReq(this.state)
    this.setState({
      name: '',
      email: '',
      phone: '',
      message: ''
    })
  }

  render () {
    return (
      <Form
        onInputChange={this.handleInputChange}
        onButtonClick={this.handleButtonClick}
        nameValue={this.state.name}
        emailValue={this.state.email}
        phoneValue={this.state.phone}
        messageValue={this.state.message}
        blockSize={this.props.blockSize}
      />
    )
  }
}

export default ContactForm
