import { Button, makeStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2)
  }
}))

const SubmitButton = ({ onButtonClick, t }) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.margin + ' link-button'}
      variant='contained'
      onClick={onButtonClick}
      size='large'
    >
      {t('send')}
    </Button>
  )
}

export default withTranslation()(SubmitButton)
