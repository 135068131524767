import React from 'react'
import ReferenceList from '../ReferenceList/referenceList'
import RenderGate from '../RenderGate/renderGate'
import { Container, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

class References extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      referencesToShow: this.props.referenceImages.slice(0, this.props.referencesPerRow),
      next: this.props.referencesPerRow,
      buttonVisible: this.props.referenceImages[this.props.referencesPerRow]
    }

    this.handleShowMoreReferences = this.handleShowMoreReferences.bind(this)
  }

  loopWithSlice (start, end) {
    const slicedReferences = this.props.referenceImages.slice(start, end)

    this.setState({
      referencesToShow: [...this.state.referencesToShow, ...slicedReferences],
      buttonVisible: this.props.referenceImages[end] || this.props.referenceImages[end + 1]
    })
  }

  handleShowMoreReferences () {
    const end = this.state.next + this.props.referencesPerRow
    this.loopWithSlice(this.state.next, end)

    this.setState({
      next: this.state.next + this.props.referencesPerRow
    })
  }

  render () {
    const { t } = this.props
    return (
      <Container className='reference-list block' disableGutters maxWidth={false}>
        <ReferenceList references={this.state.referencesToShow} perRow={this.props.referencesPerRow} />
        <RenderGate shouldRender={this.state.buttonVisible}>
          <Button className='show-more action-button' onClick={this.handleShowMoreReferences}>{t('showMore')}</Button>
        </RenderGate>
      </Container>
    )
  }
}

export default withTranslation()(References)
