import green3 from '../../media/projectImages/greeni-3.jpg'
import green4 from '../../media/projectImages/greeni-4.jpg'

const contents = ({
  green: {
    images: [green3, green3, green4],
    header: 'Kaipaako lähipelisi pientä hiomista?',
    texts: []
  },
  zip: {
    images: [green3, green3, green4],
    header: 'Kaipaako lähipelisi pientä hiomista?',
    texts: []
  },
  adventure: {
    images: [green3, green3, green4],
    header: 'Kaipaako lähipelisi pientä hiomista?',
    texts: []
  }
})

export default contents
