import RenderGate from '../RenderGate/renderGate'
import { Grid, CircularProgress } from '@material-ui/core'
import Blog from '../Blogs/Blog'
import React from 'react'
import axios from 'axios'
import exampleImage from '../../media/projectImages/greeni-4.jpg'

const blogPostsUrl = 'https://www.googleapis.com/blogger/v3/blogs/7595865628625438558/posts?key=AIzaSyDBr0LGQH9EXu_aB_vao2X9XN_wLLYU-us'

class LatestBlog extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      latestPostTitle: null,
      latestPostPublished: null,
      latestPostBlogName: null,
      latestPostAuthorImgUrl: null,
      latestPostUrl: null,
      loading: true
    }
  }

  componentDidMount () {
    axios.get(blogPostsUrl)
      .then(res => {
        if (res != null) {
          try {
            const blogPosts = res.data.items
            const latestPostDate = new Date(Math.max.apply(null, blogPosts.map(post => { return new Date(post.published) })))

            const latestPost = blogPosts.filter(post => {
              const date = new Date(post.published)
              return date.getTime() === latestPostDate.getTime()
            })[0]

            this.setState({
              latestPostTitle: latestPost.title,
              latestPostPublished: new Date(latestPost.published).toLocaleDateString('fi-Fi'),
              latestPostBlogName: latestPost.author.displayName,
              latestPostAuthorImgUrl: latestPost.author.image.url,
              latestPostUrl: latestPost.url,
              loading: false
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // This is supposed to remove a console warning referring to a memory leak in the application
  componentWillUnmount () {
    this.setState = () => {}
  }

  render () {
    return (
      <Grid container item xs={12} md={6} className='latest-blog-wrapper'>
        <RenderGate shouldRender={this.state.loading}>
          <CircularProgress className='progress-icon' />
        </RenderGate>
        <RenderGate shouldRender={this.state.latestPostTitle != null && this.state.latestPostPublished != null && this.state.latestPostBlogName != null && this.state.latestPostUrl != null}>
          <Blog
            blogImage={exampleImage}
            title={this.state.latestPostTitle}
            published={this.state.latestPostPublished}
            blogName={this.state.latestPostBlogName}
            authorImgUrl={this.state.latestPostAuthorImgUrl}
            url={this.state.latestPostUrl}
          />
        </RenderGate>
      </Grid>
    )
  }
}

export default LatestBlog
