import CookieConsent from 'react-cookie-consent'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const AcceptCookies = ({ t }) => {
  return (
    <CookieConsent location='bottom' buttonText={t('accept')} cookieName='intergreens_consent' expires={30} ariaAcceptLabel={t('accept')}>
      {t('cookieText')}
      <Link to='/privacypolicy' className='privacy-policy-link'>{t('privacyPolicy')}</Link>
    </CookieConsent>
  )
}

export default withTranslation()(AcceptCookies)
