import { withTranslation } from 'react-i18next'

const PrivacyPolicy = ({ t }) => {
  return (
    <div className='privacy-policy'>
      <h1>{t('privacyPolicy')}</h1>
      {t('policyText')}
    </div>
  )
}

export default withTranslation()(PrivacyPolicy)
