import { TextField, Grid, Typography } from '@material-ui/core'
import SubmitButton from './Button'

const Form = ({ onInputChange, onButtonClick, nameValue, emailValue, phoneValue, messageValue, blockSize }) => {
  return (
    <Grid item id='contact-form' className='contact-form-container block' xs={blockSize} md={blockSize}>
      <Grid container justify='center'>
        <Typography variant='h3' component='h3' gutterBottom>Pyydä tarjous</Typography>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          id='name'
          label='Nimi'
          name='name'
          value={nameValue}
          onChange={onInputChange}
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          id='email'
          label='Sähköpostiosoite'
          name='email'
          value={emailValue}
          onChange={onInputChange}
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          id='phone'
          label='Puhelinnumero'
          name='phone'
          value={phoneValue}
          onChange={onInputChange}
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          id='message'
          label='Vapaa teksti'
          name='message'
          value={messageValue}
          rows={6}
          multiline
          onChange={onInputChange}
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <SubmitButton
          onButtonClick={onButtonClick}
        />
      </Grid>
    </Grid>
  )
}

export default Form
