import { Grid, Grow } from '@material-ui/core'
import React from 'react'
import { withTranslation } from 'react-i18next'

const ReferenceList = ({ references, perRow, t }) => {
  // array of N elements, where N is the number of rows needed
  const rows = [...Array(Math.ceil(references.length / perRow))]

  // chunk the products into the array of rows
  const referenceRows = rows.map((ref, idx) => references.slice(idx * perRow, idx * perRow + perRow))

  let timeout = 1000
  const defaultTimeout = 1000
  // map the rows as grid elements
  const content = referenceRows.map((row, idx) => (
    <Grid key={idx}>
      {row.map((image, i) => {
        timeout = i === 0 ? defaultTimeout : i % perRow === 0 ? defaultTimeout : timeout + defaultTimeout

        return <Grow key={i} in style={{ transformOrigin: '0 0 0' }} timeout={timeout}><img alt='reference-logo' src={image} /></Grow>
      })}
    </Grid>
  ))

  return (
    <Grid>
      <Grid container justify='center' component='h3'>{t('customers')}</Grid>
      <Grid className='reference-wrapper'>
        {content}
      </Grid>
    </Grid>
  )
}

export default withTranslation()(ReferenceList)
