import { Grid, Typography, Card, CardContent, CardMedia, Link } from '@material-ui/core'
import pioneeri from '../../media/pioneeri.png'

const MediaTeaser = () => {
  return (
    <Grid item className='media-teaser' md={6}>
      <Link className='media-link' href='https://golfpiste.com/wp-content/uploads/golflehti/2020/2/#page=106' target='_blank' rel='noreferrer'>
        <Card className='media-card'>
          <CardMedia
            className='media-image'
            image={pioneeri}
            title='Tekonurmipioneeri'
          />
          <CardContent className='media-description'>
            <Typography variant='h5' component='h2'>
              Tekonurmipioneeri
            </Typography>
            <Typography variant='body2' color='textSecondary' component='p'>
              Golflehden numerossa 2/2020 perehdytään Intergreensin tarinaan.
            </Typography>
            <Link component='button' className='link-button' href='https://golfpiste.com/wp-content/uploads/golflehti/2020/2/#page=106' target='_blank' rel='noreferrer'>Lue koko artikkeli</Link>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  )
}

export default MediaTeaser
