import React from 'react'
import ProductItem from './ProductItem'
import { Container } from '@material-ui/core'
import GreenImage from '../../media/projectImages/greeni-2.jpg'
import ZipAreaImage from '../../media/projectImages/greeni-4.jpg'
import AdventureImage from '../../media/projectImages/adventure-golf.jpg'
import ProductsNavBar from './ProductsNavBar'
import { withTranslation } from 'react-i18next'

const ProductPage = ({ t }) => {
  return (
    <Container disableGutters maxWidth={false}>
      <ProductsNavBar
        greenNavImg={GreenImage}
        zipAreaNavImg={ZipAreaImage}
        adventureNavImg={AdventureImage}
        greenSubHeader={t('productGreenSub')}
        zipSubHeader={t('productZipSub')}
        adventureSubHeader={t('productAdventureSub')}
      />
      <ProductItem
        headerGreens={t('green')}
        headerZip={t('zipAreasAndGolfMats')}
        headerAdventure={t('adventureGolf')}
        id='product-item-greens'
      />

    </Container>
  )
}

export default withTranslation()(ProductPage)
