import { Container } from '@material-ui/core'
import React from 'react'
import ProductHeader from './ProductHeader'
import Carousel from '../Carousel/carousel'
import contents from '../Carousel/contents'

class ProductItem extends React.Component {
  render () {
    return (
      <Container disableGutters maxWidth={false}>
        <ProductHeader header={this.props.headerGreens} name='product-item-greens' />
        <Carousel
          images={contents.green.images}
          header={contents.green.header}
          texts={contents.green.texts}
          product='greens'
        />
        <ProductHeader header={this.props.headerZip} name='product-item-zip-area' />
        <Carousel
          images={contents.zip.images}
          header={contents.zip.header}
          texts={contents.zip.texts}
          product='zip'
        />
        <ProductHeader header={this.props.headerAdventure} name='product-item-adventure' />
        <Carousel
          images={contents.adventure.images}
          header={contents.adventure.header}
          texts={contents.adventure.texts}
          product='adventure'
        />
      </Container>
    )
  }
}

export default ProductItem
