import LatestBlog from '../Blogs/LatestBlog'
import About from '../About/About'
import ContactForm from '../ContactForm/index'
import MediaTeaser from '../Media/MediaTeaser'
import { Grid } from '@material-ui/core'

const bodyTexts = [
  'Lorem ipsum dorem solom mikä on INTERGREENS ja mitä se tekee.',
  'InterGREENS-laatu takaa viheriön kestävyyden vuosiksi eteenpäin. Erinomaisen golfgriinin lisäksi pihasi on helppohoitoinen ja hyvännäköinen.',
  'Lorem ipsum dorem solom mikä on INTERGREENS ja mitä se tekee. Lorem ipsum dorem solom mikä on INTERGREENS ja mitä se tekee.'
]
const AboutPage = () => {
  return (
    <Grid container className='about-page'>
      <About
        header='INTERGREENS'
        contents={bodyTexts}
      />
      <Grid container>
        <LatestBlog />
        <MediaTeaser />
      </Grid>
      <ContactForm blockSize={12} />
    </Grid>
  )
}

export default AboutPage
