import { axiosInstance as axios } from './axios-config'

const URL_BASEPATH = 'http://localhost:4444'

const errorHandler = error => {
  console.log('Errored', error)
}

export const postReq = (params) => axios.post(`${URL_BASEPATH}/api/mail`, params)
  .then(({ data }) => data)
  .catch(err => errorHandler(err))
