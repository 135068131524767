import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './components/App/App'
import Footer from './components/Footer/footer.js'
import Header from './components/Header/header.js'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import './styles/layout.scss'
import './i18n'

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Header />
      <App />
      <Footer />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
)

reportWebVitals()
