import Home from '../Home/index'
import ContactForm from '../ContactForm/index'
import AboutPage from '../About/AboutPage'
import { Switch, Route } from 'react-router-dom'
import React from 'react'
import ProductPage from '../ProductPage/ProductPage'
import AcceptCookies from '../AcceptCookies/AcceptCookies'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'

class App extends React.Component {
  componentDidMount () {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        })
      })
    })
  }

  render () {
    return (
      <main role='main'>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/contact' component={ContactForm} />
          <Route path='/about' component={AboutPage} />
          <Route path='/privacypolicy' component={() => <PrivacyPolicy />} />
          <Route path='/products' component={() => <ProductPage />} />
        </Switch>
        <AcceptCookies />
      </main>
    )
  }
}

export default App
