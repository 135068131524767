import { Grid, Typography } from '@material-ui/core'

const ProductHeader = ({ header, name }) => {
  return (
    <Grid container item xs={12} id={name} className='product-header'>
      <Typography variant='h1'>{header}</Typography>
    </Grid>
  )
}

export default ProductHeader
