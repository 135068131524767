import { Grid, Link, Typography, Card, CardMedia, CardContent } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

const Blog = ({ blogImage, title, published, blogName, authorImgUrl, url, t, blockSize }) => {
  return (
    <Grid item className='blog-container' xs={12} md={blockSize}>
      <Link href={url} target='_blank' rel='noreferrer'>
        <Card className='blog-card'>
          <CardMedia
            className='blog-image'
            image={blogImage}
            title='Blog image'
          />
          <CardContent className='blog-content'>
            <Grid container className='blog-author'>
              <img src={authorImgUrl} alt='Blog author' />
              <Typography variant='body2' component='p'>{blogName}</Typography>
            </Grid>
            <Typography variant='h5' component='h2'>{title}</Typography>
            <Typography className='blog-published' color='textSecondary'>{t('blogPublished')} {published}</Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  )
}

export default withTranslation()(Blog)
