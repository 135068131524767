import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import GolfCourseIcon from '@material-ui/icons/GolfCourse'

class Hero extends React.Component {
  componentDidMount () {
    setTimeout(() => {
      document.getElementById('hero').classList.add('transition')
    }, 0)

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        })
      })
    })
  }

  scrollToPosition (element) {
    const elementToScrollTo = document.getElementById(element)
    const elementPosition = elementToScrollTo.getBoundingClientRect().top
    const offsetPosition = elementPosition - 65 + window.pageYOffset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }

  render () {
    const { t } = this.props
    return (
      <Grid container id='hero' className='hero-container'>
        <Grid className='left-area'>
          <GolfCourseIcon className='golf-icon' />
          <Grid>Suomen Golfliiton virallinen yhteistyökumppani</Grid>
        </Grid>
        <Grid item xs={12} className='text-area'>
          <Typography component='h1'>
            Nauti viheriöstä omalla pihallasi ympäri vuoden.
          </Typography>
          <Typography component='h2' className='show-xs'>
            Toteutamme golfunelmia. Takaamme sinulle ammattilaistason viheriön ympärivuotisesti.
          </Typography>
          <Button className='link-button' onClick={() => this.scrollToPosition('contact-form')}>{t('contact')}</Button>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation()(Hero)
