import React from 'react'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import RenderGate from '../RenderGate/renderGate'
import { Typography } from '@material-ui/core'

class Carousel extends React.Component {
  componentDidMount () {
    this.initCarousel()
  }

  initCarousel () {
    // eslint-disable-next-line
    const swiper = new Swiper(`.swiper-container-${this.props.product}`, {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      setWrapperSize: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
          spaceBetween: 30
        }
      }
    })
  }

  render () {
    return (
      <div className='project-carousel'>
        <div className={`swiper-container swiper-container-${this.props.product}`}>
          <div className='swiper-wrapper'>
            <RenderGate shouldRender={this.props.images}>
              {this.props.images.map((image, idx) =>
                <div key={idx} className='swiper-slide'>
                  <img alt='project reference' src={image} />
                  <Typography component='div' className='slide-text-container'>
                    <RenderGate shouldRender={this.props.header}>
                      <Typography variant='h2'>
                        {this.props.header}
                      </Typography>
                    </RenderGate>
                    <RenderGate shouldRender={this.props.texts}>
                      {this.props.texts.map((text, idx) =>
                        <div key={idx} className='swiper-text'>
                          {text}
                        </div>
                      )}
                    </RenderGate>
                  </Typography>
                </div>
              )}
            </RenderGate>
          </div>
          <div className='swiper-pagination' />

          <div className='swiper-button swiper-button-prev' />
          <div className='swiper-button swiper-button-next' />
        </div>
      </div>
    )
  }
}

export default Carousel
